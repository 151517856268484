import React from 'react';
import '../../static/styles/theme.scss';

function FourOhFour() {
  return (
    <div className="text-content">
      <h1 className="text-content--heading mdc-typography--headline1">
        Can you not?
      </h1>
      <h2 className="text-content--heading mdc-typography--headline2">
        Page not found
      </h2>
      <div id="four-oh-four" className="text-content--body mdc-typography--body1">
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
      </div>
    </div>
  );
}

export default FourOhFour;
